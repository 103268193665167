
import { defineComponent } from "vue";

import TopBar from "@/components/layout/TopBar.vue";
import MenuSideBar from "@/components/layout/MenuSideBar.vue";
import LoadingOverlay from "@/components/layout/LoadingOverlay.vue";
import AuthLoadingOverlay from "@/components/layout/AuthLoadingOverlay.vue";
import SnackBar from "@/components/layout/SnackBar.vue";

import LogoutModal from "@/components/modals/LogoutModal.vue";
import AccountSettingsModal from "@/components/modals/AccountSettingsModal.vue";
import WalletSettingsModal from "@/components/modals/WalletSettingsModal.vue";
import ChangeLanguageModal from "@/components/modals/ChangeLanguageModal.vue";
import ChangeThemeModal from "@/components/modals/ChangeThemeModal.vue";
import ChangeUsernameModal from "@/components/modals/ChangeUsernameModal.vue";
import ChangeEmailModal from "@/components/modals/ChangeEmailModal.vue";
import ChangePasswordModal from "@/components/modals/ChangePasswordModal.vue";
import AccountDeleteModal from "@/components/modals/AccountDeleteModal.vue";
import DevicesModal from "@/components/modals/DevicesModal.vue";
import TwoFactorEnableModal from "@/components/modals/TwoFactorEnableModal.vue";
import TwoFactorDisableModal from "@/components/modals/TwoFactorDisableModal.vue";
import ChangeCurrencyModal from "@/components/modals/ChangeCurrencyModal.vue";
import HelpBox from "@/components/modals/HelpBox.vue";
import AdvicesConfigModal from "@/components/modals/AdvicesConfigModal.vue";

import WalletChangePasswordModal from "@/components/modals/wallets/WalletChangePasswordModal.vue";
import WalletExportKeyModal from "@/components/modals/wallets/WalletExportKeyModal.vue";
import WalletReplaceModal from "@/components/modals/wallets/WalletReplaceModal.vue";

import PublicProfileModal from "@/components/modals/PublicProfileModal.vue";

import MessageModal from "@/components/modals/MessageModal.vue";

import { AuthController } from "@/control/auth";
import { AppEvents } from "@/control/app-events";
import { AppPreferences } from "@/control/app-preferences";

const unauthorizedAllowedRoutes = [
  "login",
  "signup",
  "signup-success",
  "tfa-login",
  "forgot-password",
  "reset-password",
  "verify-email",
  "wallet-create",
  "invalid-certificate",
  "valid-certificate-callback",
];

export default defineComponent({
  components: {
    TopBar,
    MenuSideBar,
    LoadingOverlay,
    AuthLoadingOverlay,
    SnackBar,
    LogoutModal,
    AccountSettingsModal,
    WalletSettingsModal,
    ChangeLanguageModal,
    ChangeThemeModal,
    ChangeUsernameModal,
    ChangeEmailModal,
    ChangePasswordModal,
    AccountDeleteModal,
    DevicesModal,
    TwoFactorEnableModal,
    TwoFactorDisableModal,
    WalletChangePasswordModal,
    WalletExportKeyModal,
    WalletReplaceModal,
    ChangeCurrencyModal,
    PublicProfileModal,
    MessageModal,
    HelpBox,
    AdvicesConfigModal,
  },
  name: "MainLayout",
  data: function () {
    return {
      sidebarExpanded: true,
      isDarkTheme: false,
      loading: !AuthController.isAuthenticated(),
      loadingAuth: AuthController.Loading,

      displayModalLogout: false,
      displayModalAccountSettings: false,
      displayModalWalletSettings: false,
      displayModalLanguage: false,
      displayModalTheme: false,
      displayModalCurrency: false,
      displayModalChangeUsername: false,
      displayModalChangeEmail: false,
      displayModalChangePassword: false,
      displayModalDeleteAccount: false,
      displayModalDevices: false,
      displayModalAdvicesConfig: false,
      displayModalTwoFactorAuthEnable: false,
      displayModalTwoFactorAuthDisable: false,

      displayModalWalletPassword: false,
      displayModalWalletExport: false,
      displayModalWalletReplace: false,

      displayModalProfile: false,

      displayModalMessage: false,
      messageModalTitle: "",
      messageModalMessage: "",

      displayHelpBox: false,
    };
  },
  methods: {
    onAuthStatusUpdate: function () {
      this.loading = !AuthController.isAuthenticated();

      this.displayModalLogout = false;
      this.displayModalAccountSettings = false;
      this.displayModalChangeUsername = false;
      this.displayModalChangeEmail = false;
      this.displayModalChangePassword = false;
      this.displayModalDeleteAccount = false;
      this.displayModalDevices = false;
      this.displayModalAdvicesConfig = false;
      this.displayModalTwoFactorAuthEnable = false;
      this.displayModalTwoFactorAuthDisable = false;
      this.displayModalWalletSettings = false;
      this.displayHelpBox = false;
      
      if (AuthController.Loading) {
        return;
      }

      if (
        this.loading &&
        !unauthorizedAllowedRoutes.includes(this.$route ? this.$route.name : "")
      ) {
        AuthController.PageToGo = this.$route ? (this.$route.name || "home") : "home";
        AuthController.PageToGoParams = this.$route ? (this.$route.params || {}) : {};
        if (AuthController.isAskingForTwoFactor()) {
          this.$router.push({ name: "tfa-login" });
        } else if (AuthController.isAskingForWallet()) {
          this.$router.push({ name: "wallet-create" });
        } else {
          this.$router.push({ name: "login" });
        }
      }
    },

    onAuthLoadingChanged: function () {
      this.loadingAuth = AuthController.Loading;
    },

    onThemeChanged: function () {
      this.isDarkTheme = AppPreferences.Theme === "dark";
    },

    toggleMenu: function () {
      this.sidebarExpanded = !this.sidebarExpanded;
    },

    closeSideBar: function () {
      this.sidebarExpanded = false;
    },

    skipMainContent: function (event) {
      if (event) {
        event.preventDefault();
      }
      const content: HTMLElement = document.querySelector(".page-content");
      if (content) {
        content.focus();
      }
    },

    showMessage: function (title: string, msg: string) {
      this.messageModalTitle = title;
      this.messageModalMessage = msg;
      this.displayModalMessage = true;
    },
  },
  mounted: function () {
    if (document.documentElement.clientWidth < 1000) {
      this.sidebarExpanded = false;
    } else {
      this.sidebarExpanded = true;
    }

    this.$options.onAuthStatusUpdateH = this.onAuthStatusUpdate.bind(this);
    AppEvents.AddEventListener(
      "auth-status-changed",
      this.$options.onAuthStatusUpdateH
    );

    this.$options.onAuthLoadingChangedH = this.onAuthLoadingChanged.bind(this);
    AppEvents.AddEventListener(
      "auth-status-loading",
      this.$options.onAuthLoadingChangedH
    );

    this.$options.onThemeChangedH = this.onThemeChanged.bind(this);
    AppEvents.AddEventListener("theme-changed", this.$options.onThemeChangedH);
    this.onThemeChanged();

    this.$options.showMessageH = this.showMessage.bind(this);
    AppEvents.AddEventListener("msg-modal", this.$options.showMessageH);

    this.onAuthStatusUpdate();
  },
  beforeUnmount: function () {
    AppEvents.RemoveEventListener(
      "auth-status-changed",
      this.$options.onAuthStatusUpdateH
    );
    AppEvents.RemoveEventListener(
      "auth-status-loading",
      this.$options.onAuthLoadingChangedH
    );
    AppEvents.RemoveEventListener(
      "theme-changed",
      this.$options.onThemeChangedH
    );

    AppEvents.RemoveEventListener("msg-modal", this.$options.showMessageH);
  },
  watch: {
    $route: function () {
      this.onAuthStatusUpdate();
      if (document.documentElement.clientWidth < 1000) {
        this.sidebarExpanded = false;
      } else {
        this.sidebarExpanded = true;
      }
    },
  },
});
