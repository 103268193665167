// Vue entry point

"use strict";

import { VueReCaptcha } from 'vue-recaptcha-v3'
import VueApexCharts from "vue3-apexcharts";
import { AppPreferences } from "./control/app-preferences";
import { makeApplicationRouter } from './routes';
import { createApp } from 'vue'
import { i18n } from './i18n'
import App from './App.vue'
import { AuthController } from './control/auth';
import { AppEvents } from './control/app-events';
import { NotificationsController } from './control/notifications';
import { AdvicesController } from './control/advices';

// Initialize stuff

AppPreferences.LoadPreferences();
AuthController.Initialize();
NotificationsController.Initialize();
AdvicesController.Initialize();

// Global event handlers

document.addEventListener("drop", function (e) {
    e.preventDefault();
    e.stopPropagation();
});

document.addEventListener("dragover", function (e) {
    e.preventDefault();
    e.stopPropagation();
});

window.addEventListener("message", function(event) {
    if (event.origin === window.origin) {
        if (typeof event.data === "object") {
            if (event.data.type === "certificate-validated") {
                AppEvents.Emit("certificate-validated", event.data.hash + "", event.data.token + "", event.data.subject + "")
            }
        }
    }
});

// Setup App

createApp(App)
    .use(i18n) // Multi-language
    .use(makeApplicationRouter()) // Router
    .use(VueReCaptcha, { siteKey: process.env.VUE_APP_RECAPTCHA_SITE_ID }) // Recaptcha
    .use(VueApexCharts) // Charts library
    .mount('#app');
