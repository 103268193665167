
import { AppEvents } from "@/control/app-events";
import { AppPreferences } from "@/control/app-preferences";
import { AuthController } from "@/control/auth";
import { defineComponent, nextTick } from "vue";
import { useVModel } from "../../utils/vmodel";
import { FocusTrap } from "../../utils/focus-trap";

export default defineComponent({
  name: "AccountSettingsModal",
  emits: ["update:display"],
  props: {
    display: Boolean,
  },
  setup(props) {
    return {
      displayStatus: useVModel(props, "display"),
    };
  },
  data: function () {
    return {
      username: AuthController.Username,
      walletAddress: AuthController.Wallet ? AuthController.Wallet.address : "",
      tfa: AuthController.RequiresTwoFactorAuthentication,
      admin: AuthController.GlobalAdmin,
      languages: AppPreferences.AvailableLanguages.slice(),
      lang: AppPreferences.Language,
      isDarkTheme: AppPreferences.Theme === "dark",
      currency: AppPreferences.Currency,
    };
  },
  methods: {
    open: function () {
      this.displayStatus = true;
      this.lang = AppPreferences.Language;
      this.isDarkTheme = AppPreferences.Theme === "dark";
      this.currency = AppPreferences.Currency;
    },

    close: function () {
      this.displayStatus = false;
    },

    escapeToClose: function (event) {
      if (event.key === "Escape") {
        this.close();
      }
    },

    logout: function () {
      AppEvents.Emit("logout-open");
    },

    stopPropagationEvent: function (e) {
      e.stopPropagation();
    },

    onAuthChanged: function () {
      this.username = AuthController.Username;
      this.walletAddress = AuthController.Wallet ? AuthController.Wallet.address : "";
      this.tfa = AuthController.RequiresTwoFactorAuthentication;
      this.admin = AuthController.GlobalAdmin;
    },

    clickOnEnter: function (event) {
      if (event.key === "Enter") {
        event.preventDefault();
        event.stopPropagation();
        event.target.click();
      }
    },

    renderLanguage: function (lang, languages) {
      for (let l of languages) {
        if (l.id === lang) {
          return l.name;
        }
      }
      return "-";
    },

    renderWallet: function (addr: string) {
      return addr.substring(0, 8) + "..." + addr.substring(36);
    },

    openSelectLanguage: function () {
      this.close();
      AppEvents.Emit("select-language-open");
    },

    openWalletSettings: function () {
      this.close();
      AppEvents.Emit("wallet-settings-open");
    },

    openCurrencyChange: function () {
      this.close();
      AppEvents.Emit("select-currency-open");
    },

    renderTheme: function (isDark) {
      if (isDark) {
        return this.$t("Dark");
      } else {
        return this.$t("Light");
      }
    },

    openSelectTheme: function () {
      this.close();
      AppEvents.Emit("select-theme-open");
    },

    openProfile: function() {
      this.$router.push({ name: "profile" });
    },

    openChangeUsername: function () {
      AppEvents.Emit("change-username-open");
    },

    openChangeEmail: function () {
      AppEvents.Emit("change-email-open");
    },

    openChangePassword: function () {
      AppEvents.Emit("change-password-open");
    },

    openAccountDelete: function () {
      AppEvents.Emit("delete-account-open");
    },

    openDevicesList: function () {
      AppEvents.Emit("devices-list-open");
    },

    openAdviceConfig: function () {
      AppEvents.Emit("advices-config-open");
    },

    enableTFAOpen: function () {
      AppEvents.Emit("tfa-enable-open");
    },

    disableTFAOpen: function () {
      AppEvents.Emit("tfa-disable-open");
    },
  },
  mounted: function () {
    this.$options.openH = this.open.bind(this);
    AppEvents.AddEventListener("account-settings-open", this.$options.openH);

    this.$options.onAuthChangedH = this.onAuthChanged.bind(this);
    AppEvents.AddEventListener(
      "auth-status-changed",
      this.$options.onAuthChangedH
    );

    this.$options.focusTrap = new FocusTrap(this.$el, this.close.bind(this));
  },
  beforeUnmount: function () {
    AppEvents.RemoveEventListener("account-settings-open", this.$options.openH);
    AppEvents.RemoveEventListener(
      "auth-status-changed",
      this.$options.onAuthChangedH
    );
    if (this.$options.focusTrap) {
      this.$options.focusTrap.destroy();
    }
  },
  watch: {
    display: function () {
      if (this.display) {
        if (this.$options.focusTrap) {
          this.$options.focusTrap.activate();
        }
        nextTick(() => {
          this.$el.focus();
        });
      } else {
        if (this.$options.focusTrap) {
          this.$options.focusTrap.deactivate();
        }
      }
    },
  },
});
