
import { AppEvents } from "@/control/app-events";
import { defineComponent } from "vue";
import { NotificationsController } from "@/control/notifications";
import { AuthController } from "@/control/auth";
import { GetAssetURL } from "@/utils/request";

import NotificationsModal from "@/components/modals/NotificationsModal.vue";

export default defineComponent({
  components: {
    NotificationsModal
  },
  name: "TopBar",
  emits: ['toggle-menu'],
  props: {
    dark: Boolean,
  },
  data: function () {
    return {
      hasNotifications: NotificationsController.UnreadCount > 0,
      displayNotifications: false,

      image: AuthController.Image,
    };
  },
  methods: {
    openUserSettings: function () {
      AppEvents.Emit("account-settings-open");
    },

    openNotifications: function() {
      this.displayNotifications = true;
    },

    openNewLoanRequest: function() {
      this.$router.push({ name: "loan-new-request" });
    },

    clickMenu: function () {
      this.$emit("toggle-menu");
    },

    notificationsChanged: function () {
      this.hasNotifications = NotificationsController.UnreadCount > 0;
    },

    getAsset: function (url: string) {
      return GetAssetURL(url);
    },

    onAuthStatusUpdate: function () {
      this.image = AuthController.Image;
    },
  },
  mounted: function () {
    this.$options.onAuthStatusUpdateH = this.onAuthStatusUpdate.bind(this);
    AppEvents.AddEventListener("auth-status-changed", this.$options.onAuthStatusUpdateH);

    this.$options.notificationsChangedH = this.notificationsChanged.bind(this);
    AppEvents.AddEventListener("notifications-status-changed", this.$options.notificationsChangedH);
  },
  beforeUnmount: function () {
    AppEvents.RemoveEventListener("auth-status-changed", this.$options.onAuthStatusUpdateH);
    AppEvents.RemoveEventListener("notifications-status-changed", this.$options.notificationsChangedH);
  },
});
