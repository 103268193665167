
import { AppEvents } from "@/control/app-events";
import { GetAssetURL, Request } from "@/utils/request";
import { renderDate, renderDateAndTime } from "@/utils/time-utils";
import { defineComponent, nextTick } from "vue";
import { useVModel } from "../../utils/vmodel";
import { FocusTrap } from "../../utils/focus-trap";
import { ProfileAPI } from "@/api/api-profile";
import { Timeouts } from "@/utils/timeout";
import { UserRatingAPI } from "@/api/api-user-rating";
import UserLink from "@/components/utils/UserLink.vue";
import { escapeHTML } from "@/utils/text";
import { UserCommentAPI } from "@/api/api-user-comment";
import { AuthController } from "@/control/auth";

export default defineComponent({
  components: {
    UserLink
  },
  name: "PublicProfileModal",
  emits: ["update:display"],
  props: {
    display: Boolean,
  },
  setup(props) {
    return {
      displayStatus: useVModel(props, "display"),
    };
  },
  data: function () {
    return {
      uid: "",

      username: "",
      role: "",
      name: "",
      image: "",
      url: "",
      location: "",
      bio: "",
      created: 0,

      risk: -1,
      busy: false,
      comments: [],
      hasMoreComments: true,

      activeContracts: 0,
      paidContracts: 0,
      cancelledDefaultContracts: 0,
      averageRating: "",
      userComment: "",
      rating: 0,
      currentUserRating: 0,
      userEvaluated: ""
    };
  },
  methods: {
    autoFocus: function () {
      nextTick(() => {
        this.$el.focus();
      });
    },

    getAsset: function (url: string) {
      return GetAssetURL(url);
    },

    open: function (uid: string) {
      this.uid = uid || "";
      this.comments = [];
      this.getCurrentUserRating();
      this.loadComments();

      Timeouts.Abort("public-profile-modal-load");
      Request.Abort("public-profile-modal-load");

      if (!this.uid) {
        this.username = "";
        this.role = "";
        this.name = "";
        this.image = "";
        this.url = "";
        this.location = "";
        this.bio = "";
        this.created = 0;
        this.averageRating = ""
        this.risk = -1;
        this.activeContracts = 0;
        this.paidContracts = 0;
        this.cancelledDefaultContracts = 0;
        this.displayStatus = true;
        this.autoFocus();
        return;
      }
      Request.Pending(
        "public-profile-modal-load",
        ProfileAPI.GetProfile(this.uid)
      )
        .onSuccess((response) => {
          this.username = response.username;
          this.role = response.role;
          this.name = response.name;
          this.image = response.image;
          this.url = response.url;
          this.location = response.location;
          this.bio = response.bio;
          this.created = response.created;
          this.averageRating = response.averageRating;

          this.risk = response.defaultRisk;

          this.activeContracts = response.signedContractsCount;
          this.paidContracts = response.finishedContractsCount;
          this.cancelledDefaultContracts = response.cancelledDefaultContractsCount;

          this.displayStatus = true;
          this.autoFocus();
        })
        .onRequestError((err) => {
          Request.ErrorHandler()
            .add(401, "*", () => {
              AppEvents.Emit("unauthorized");
            })
            .add(404, "*", () => {
              this.username = "";
              this.name = "";
              this.image = "";
              this.url = "";
              this.location = "";
              this.bio = "";
              this.created = 0;
              this.averageRating = "";
              this.displayStatus = true;
              this.autoFocus();
            })
            .add("*", "*", () => {
              // Retry
              Timeouts.Set("public-profile-modal-load", 2000, this.open.bind(this));
            })
            .handle(err);
        })
        .onUnexpectedError((err) => {
          console.error(err);
          Timeouts.Set("public-profile-modal-load", 2000, this.open.bind(this));
        });
    },

    getCurrentUserRating: function() {
      Timeouts.Abort("current-user-rating-load");
      Request.Abort("current-user-rating-load");

      Request.Pending(
        "current-user-rating-load",
        UserRatingAPI.GetCurrentUserRating(this.uid)
      )
        .onSuccess((response) => {
          this.currentUserRating = response.rating;

          this.displayStatus = true;
          this.autoFocus();
        })
        .onRequestError((err) => {
          Request.ErrorHandler()
            .add(401, "*", () => {
              AppEvents.Emit("unauthorized");
            })
            .add(404, "*", () => {
              
              this.displayStatus = true;
              this.autoFocus();
            })
            .add("*", "*", () => {
              // Retry
              Timeouts.Set("current-user-rating-load", 2000, this.open.bind(this));
            })
            .handle(err);
        })
        .onUnexpectedError((err) => {
          console.error(err);
          Timeouts.Set("current-user-rating-load", 2000, this.open.bind(this));
        });
    },

    setUserRating: function() {
      Timeouts.Abort("set-user-rating");
      Request.Abort("set-user-rating");

      Request.Pending(
        "set-user-rating",
        UserRatingAPI.SetRating(this.userEvaluated, this.rating)
      )
        .onSuccess(response => {
          this.averageRating = response.newAverageRating;
        })
        .onRequestError((err) => {
          Request.ErrorHandler()
            .add(401, "*", () => {
              AppEvents.Emit("unauthorized");
            })
            .add(404, "*", () => {
              
              this.displayStatus = true;
              this.autoFocus();
            })
            .add("*", "*", () => {
              // Retry
              Timeouts.Set("set-user-rating", 2000, this.open.bind(this));
            })
            .handle(err);
        })
        .onUnexpectedError((err) => {
          console.error(err);
          Timeouts.Set("set-user-rating", 2000, this.open.bind(this));
        });
    },

    setEvaluation: function(evaluatedUserId, evaluation){
      this.userEvaluated = evaluatedUserId;
      this.rating = evaluation;
      this.currentUserRating = evaluation;
      this.setUserRating();
    },

    loadComments: function () {
      Timeouts.Abort("user-comments-load");
      Request.Abort("user-comments-load");

      let oldest = Date.now();

      if (this.comments.length > 0) {
        oldest = this.comments[this.comments.length - 1].date;
      }

      this.loadingComments = true;

      if (!AuthController.isAuthenticated()) {
        return;
      }

      Request.Pending(
        "loan-offer-messages-load",
        UserCommentAPI.ListComments(this.uid, oldest)
      )
        .onSuccess((comments) => {
          for (let com of comments) {
            this.comments.push(com);
          }
          this.loadingComments = false;
          this.hasMoreComments = comments.length > 0;
        })
        .onRequestError((err) => {
          Request.ErrorHandler()
            .add(401, "*", () => {
              AppEvents.Emit("unauthorized");
            })
            .add(404, "*", () => {
              this.loadingComments = false;
              this.hasMoreComments = false;
            })
            .add("*", "*", () => {
              Timeouts.Set("user-comments-load", 2000, this.loadComments.bind(this));
            })
            .handle(err);
        })
        .onUnexpectedError((err) => {
          console.error(err);
          Timeouts.Set("user-comments-load", 2000, this.loadComments.bind(this));
        });
    },

    sendComment: function () {
      if (this.busy) {
        return;
      }

      this.busy = true;

      Request.Do(UserCommentAPI.SendComment(this.uid, this.userComment)).onSuccess(() => {
        this.busy = false;
        AppEvents.ShowSnackBar(this.$t("Comment sent"));
        this.userComment = "";
        this.hasMoreComments = true;
        this.comments = [];
        this.loadComments();
      }).onRequestError(err => {
        this.busy = false;
        Request.ErrorHandler()
          .add(401, "*", () => {
            AppEvents.Emit("unauthorized");
            AppEvents.ShowSnackBar(this.$t("Access denied"));
          })
          .add(400, "INVALID_COMMENT", () => {
            AppEvents.ShowSnackBar(this.$t("Invalid message"));
          })
          .add(500, "*", () => {
            AppEvents.ShowSnackBar(this.$t("Internal server error"));
          })
          .add("*", "*", () => {
            AppEvents.ShowSnackBar(this.$t("Could not connect to the server"));
          })
          .handle(err);
      }).onUnexpectedError(err => {
        this.busy = false;
        console.error(err);
      });
    },

    close: function () {
      this.displayStatus = false;
    },

    escapeToClose: function (event) {
      if (event.key === "Escape") {
        this.close();
      }
    },

    stopPropagationEvent: function (e) {
      e.stopPropagation();
    },

    renderCommentDate: function (ts: number) {
      return renderDateAndTime(ts, this.$t);
    },

    renderDate: function (t) {
      return renderDate(t, this.$t);
    },

    renderRisk: function (r: number): string {
      if (r < 0) {
        return this.$t("Unknown");
      } else {
        return (Math.round(r * 100) / 100) + "%";
      }
    },

    renderComment: function (m: string) {
      return escapeHTML(m).replace(/\n/g, "<br>");
    },
  },
  mounted: function () {
    this.$options.openH = this.open.bind(this);
    AppEvents.AddEventListener("profile-open", this.$options.openH);

    this.$options.focusTrap = new FocusTrap(this.$el, this.close.bind(this));
  },
  beforeUnmount: function () {
    Timeouts.Abort("public-profile-modal-load");
    Request.Abort("public-profile-modal-load");

    AppEvents.RemoveEventListener("profile-open", this.$options.openH);
    this.$options.focusTrap.destroy();
  },
  watch: {
    display: function () {
      if (this.display) {
        this.$options.focusTrap.activate();
        nextTick(() => {
          this.$el.focus();
        });
      } else {
        this.$options.focusTrap.deactivate();
      }
    },
  },
});
