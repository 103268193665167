
import { AppEvents } from "@/control/app-events";
import { AuthController } from "@/control/auth";
import { defineComponent } from "vue";
import { useVModel } from "../../utils/vmodel";

export default defineComponent({
  components: {},
  name: "MenuSideBar",
  emits: ["update:expanded"],
  props: {
    expanded: Boolean,
  },
  setup(props) {
    return {
      expandedStatus: useVModel(props, "expanded"),
    };
  },
  data: function () {
    return {
      page: '',
      isAdmin: AuthController.GlobalAdmin,
      hasIdentityVerificationPermission: AuthController.hasPermission("identity_verification"),
      walletKind: AuthController.Wallet ? AuthController.Wallet.kind : "",
    };
  },
  methods: {
    updatePage: function () {
      this.page = this.$route ? (this.$route.name) : '';
    },

    onAuthChanged: function () {
      this.isAdmin = AuthController.GlobalAdmin;
      this.hasIdentityVerificationPermission = AuthController.hasPermission("identity_verification");
      this.walletKind = AuthController.Wallet ? AuthController.Wallet.kind : "";
    },

    logout: function () {
      AppEvents.Emit("logout-open");
    },
  },
  mounted: function () {
    this.$options.onAuthChangedH = this.onAuthChanged.bind(this);
    AppEvents.AddEventListener(
      "auth-status-changed",
      this.$options.onAuthChangedH
    );

    this.updatePage();
  },
  beforeUnmount: function () {
    AppEvents.RemoveEventListener(
      "auth-status-changed",
      this.$options.onAuthChangedH
    );

    AppEvents.RemoveEventListener(
      "notifications-status-changed",
      this.$options.notificationsChangedH
    );
  },
  watch: {
    $route: function () {
      this.updatePage();
    },
  },
});
